<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Documentos necesarios para el viaje</b-card-title>
      <b-card-sub-title>En este Modulo se pueden agregar todos los documentos que se necesitan para cumplir con el proceso</b-card-sub-title>
      <hr>
      <vue-documents
        :users="user"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import IndexDocumentos from '@/components/documents/IndexDocumentUserList.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    'vue-documents': IndexDocumentos,
  },
  computed: {
    user: {
      get() {
        return JSON.parse(localStorage.getItem('userData'))
      },
      set(val) {
        return val
      },
    },
  },
}
</script>

<style>

</style>
