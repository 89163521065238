<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Contactos de familiares ó acudientes</b-card-title>
      <b-card-sub-title>En este modulo  puedes agregar contactos de emergencia o acudientes del estudiante y colocarlos como responsables del contrato</b-card-sub-title>
      <hr>
      <vue-family
        :users="user"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import IndexFamily from '@/views/family/FamilyList.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    'vue-family': IndexFamily,
  },
  computed: {
    user: {
      get() {
        return JSON.parse(localStorage.getItem('userData'))
      },
      set(val) {
        return val
      },
    },
  },
}
</script>

<style>

</style>
