<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <analytics-congratulation v-if="false" :data="user" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
  },
  data() {
    return {
      data: {},
      user: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    // data
  },
  methods: {
    kFormatter,
  },
}
</script>
