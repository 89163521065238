<template>
  <div>
    <b-alert
      variant="danger"
      :show="isContract == null"
    >
      <h4 class="alert-heading">
        Este usuario no posee contrato
      </h4>
    </b-alert>
    <div
      v-if="isContract != null"
    >
      <b-tabs>
        <b-tab
          title="Documentos"
          active
        >
          <b-col cols="12">
            <good-table
              :columns="columns"
              :rows="rows"
              :select-row="true"
              :is_active_create="$can('create', 'documentosestudiante')"
              :is_active_edit="$can('update', 'documentosestudiante')"
              :is_active_trash="$can('delete', 'documentosestudiante')"
              :is_active_trash_all="$can('delete', 'documentosestudiante')"
              :is-loading="isLoading"
              @Crear="Crear"
              @Edit_one="Edit_one"
              @edit="Edit"
              @delete-register="deleteRegister"
              @delete-all="deleteAll"
              @show-archive-document="showArchive"
              @show-archive-traducido="showArchiveTraducido"
              @change-status-document-select="changeStatusDocumentSelect"
              @change-status-document-traducido-select="changeStatusDocumentTraducidoSelect"
              @selection-changed="selectionChanged"
            />
          </b-col>
        </b-tab>
        <b-tab
          title="Papelera"
        >
          <b-col cols="12">
            <good-table
              :columns="columnsDelete"
              :rows="rowsDelete"
              :select-row="true"
              :is_active_trash_back="$can('delete', 'documentosestudiante')"
              :is_active_trash_all_back="$can('delete', 'documentosestudiante')"
              @delete-register-back="deleteRegisterBack"
              @delete-all-back="deleteAllBack"
              @show-archive="showArchive"
              @selection-changed="selectionChangedDelete"
            />
          </b-col>
        </b-tab>
      </b-tabs>
      <vue-form
        v-if="modalShowDocumento"
        :modal-show="modalShowDocumento"
        :info-data="infoData"
        :user-i-d="userID"
        :pais-i-d="paisID"
        @create-result="getContract"
        @edit-result="getContract"
        @close="closeShowDocumento"
      />
      <vue-form-translate
        v-if="modalShowDocumentoEditar"
        :modal-show="modalShowDocumentoEditar"
        :info-data="infoData"
        :user-i-d="userID"
        :pais-i-d="paisID"
        @create-result="getContract"
        @edit-result="getContract"
        @close="closeShowDocumentoEditar"
      />
      <show-archive
        size="xl"
        ancho="750px"
        :modal-show="modalShowArchive"
        :get-file-path="getFilePath"
        @close="close2"
      />
    </div>
    <vue-status
      v-if="modalChangeStatus"
      :modal-show="modalChangeStatus"
      :url-update="urlUpdate"
      :id-documento="idDocumento"
      @edit-result="getContract"
      @close="closeChangeTraducidoSelect"
    />
  </div>
</template>

<script>
import {
  BCol, BTabs, BTab, BAlert,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/components/documents/formDocumentosStudiantesList.vue'
import FormTranslate from '@/components/documents/formDocumentosStudiantesModal.vue'
import ShowArchive from '@/components/documents/ShowArchive.vue'
import ChangeStatus from '@/components/documents/ChangeStatus.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    GoodTable,
    BTabs,
    BTab,
    BAlert,
    'vue-form': Form,
    'vue-form-translate': FormTranslate,
    'show-archive': ShowArchive,
    'vue-status': ChangeStatus,
  },
  directives: {
    Ripple,
  },
  props: {
    users: {
      type: Object,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      rowsDelete: [],
      selectRow: null,
      selecction: [],
      selecctionDelete: [],
      modalShow2: false,
      modalShowArchive: false,
      modalShowDocumento: false,
      modalShowDocumentoEditar: false,
      modalChangeStatus: false,
      userID: 0,
      paisID: 0,
      urlUpdate: null,
      idDocumento: [],
      infoData: {},
      getFilePath: '',
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Documento',
          field: 'archivo_normal',
          tdClass: 'text-center',
        },
        {
          label: 'Estado documento',
          field: 'status.name',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar estado',
          },
        },
        {
          label: 'Motivo de rechazo de documento',
          field: 'motivo',
          tdClass: 'text-center',
        },
        {
          label: 'Documento traducido',
          field: 'documento_traducido.archivo_traducir',
          tdClass: 'text-center',
        },
        {
          label: 'Estado documento traducido',
          field: 'documento_traducido.status.name',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar estado',
          },
        },
        {
          label: 'Motivo de rechazo de documento traducido',
          field: 'documento_traducido.motivo',
          tdClass: 'text-center',
        },
      ],
      columnsDelete: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar nombre',
          },
        },
        {
          label: 'Documento',
          field: 'archivo_normal',
          tdClass: 'text-center',
        },
        {
          label: 'Documento traducido',
          field: 'documento_traducido.archivo_traducir',
          tdClass: 'text-center',
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
        {
          label: 'Borrado',
          field: 'deleted_at',
          formatFn: this.formatFn,
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar Fecha',
          },
        },
      ],
    }
  },
  computed: {
    isContract() {
      return JSON.parse(localStorage.getItem('userData')).contrato
    },
  },
  created() {
    this.getContract()
    this.getContractDelete()
  },
  methods: {
    closeShowDocumento() {
      this.modalShowDocumento = false
      this.infoData = {}
    },
    closeShowDocumentoEditar() {
      this.modalShowDocumentoEditar = false
      this.infoData = {}
    },
    close() {
      this.$emit('close')
    },
    close2() {
      this.modalShowArchive = false
      this.getFilePath = ''
    },
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    getContract() {
      this.isLoading = true
      this.$http.get(`/v1/document-studiante/get/${this.users.id}`)
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    getContractDelete() {
      this.$http.get(`/v1/document-studiante/get/delete/${this.users.id}`)
        .then(res => { this.rowsDelete = res.data })
    },
    Crear() {
      this.modalShowDocumento = true
      this.infoData = {}
      this.userID = this.users.id
      this.paisID = this.users.viaje.pais_id_universidad
    },
    Edit(val) {
      this.infoData = val
      this.userID = val.user_id
      this.paisID = this.users.viaje.pais_id_universidad
      this.modalShowDocumentoEditar = true
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShowDocumentoEditar = true
      }
    },
    deleteRegister(val) {
      this.$http.delete(`/v1/document-studiante/delete/${val.id}`).then(res => {//eslint-disable-line
        this.getContract()
        this.getContractDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/document-studiante/delete/', {
          id_documents: this.selecction,
        }).then(res => {//eslint-disable-line
          this.getContract()
          this.getContractDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
    selectionChangedDelete(val) {
      this.selecctionDelete = val.selectedRows
    },
    deleteRegisterBack(val) {
      this.$http.delete(`/v1/document-studiante/delete/restore/${val.id}`).then(res => {//eslint-disable-line
        this.getContract()
        this.getContractDelete()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha Restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha Restaurado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteAllBack() {
      if (this.selecctionDelete.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton actualizar estado debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/document-studiante/delete/restoreall', {
          id_documents: this.selecctionDelete,
        }).then(res => {//eslint-disable-line
          this.getContract()
          this.getContractDelete()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registro seleccionado se han restaurado correctamente.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    showArchive(val) {
      let carpeta = ''
      let archivo  = val.archivo //eslint-disable-line
      carpeta = 'document_users'
      this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
      this.modalShowArchive = true
    },
    showArchiveTraducido(val) {
      let carpeta = ''
      carpeta = 'document_users'
      let archivo = val.documento_traducido.archivo //eslint-disable-line
      this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
      this.modalShowArchive = true
    },
    async changeStatusDocumentSelect() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.modalChangeStatus = true
        this.urlUpdate = 'document-studiante'
        this.idDocumento = await this.setIdDocumento(this.selecction)
      }
    },
    async changeStatusDocumentTraducidoSelect() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton elimanar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.modalChangeStatus = true
        this.urlUpdate = 'document-traducido'
        this.idDocumento = await this.setIdDocumentoTraducido(this.selecction)
      }
    },
    async setIdDocumento(val) {
      const arrayMap = []
      for (let i = 0; i < val.length; i += 1) {
        arrayMap.push(val[i].id)
      }
      return arrayMap
    },
    async setIdDocumentoTraducido(val) {
      const arrayMap = []
      for (let i = 0; i < val.length; i += 1) {
        arrayMap.push(val[i].documento_traducido.id)
      }
      return arrayMap
    },
    closeChangeTraducidoSelect() {
      this.modalChangeStatus = false
    },
  },
}
</script>
